import { createSlice } from '@reduxjs/toolkit'

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        pageTitle: "Home",  // current page title state management
        noOfNotifications : 15,  // no of unread notifications
        newNotificationMessage : "",  // message of notification to be shown
        newNotificationStatus : 1,   // to check the notification type -  success/ error/ info
        clickGalleryImage: '', 
        clickGalleryImageInVc: '', 
        deleteGalleryImage: '',
        deleteAccount: '',
        clickAdvanceImage: '',
    },
    reducers: {
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload.title
        },

        removeNotificationMessage: (state, action) => {
            state.newNotificationMessage = ""
        },

        showNotification: (state, action) => {
            state.newNotificationMessage = action.payload.message
            state.newNotificationStatus = action.payload.status
        },

        setClickFromGallery: (state, action) => {
            state.clickGalleryImage = action.payload.galleryimage
        },

        setClickFromInVC: (state, action) => {
            state.clickGalleryImageInVc = action.payload.galleryimageInVc
        },

        setDeleteFromGallery: (state, action) => {
            state.deleteGalleryImage = action.payload.deleteallgalleryimage
        },

        setDeleteAccount: (state, action) => {
            state.deleteAccount = action.payload.deleteAccount
        },

        setAdvanceImagedFromList: (state, action) => {
            state.clickAdvanceImage = action.payload.advanceimage
        }
    }
})

export const { setPageTitle, removeNotificationMessage, showNotification, setClickFromGallery ,setClickFromInVC,  setDeleteFromGallery,setDeleteAccount, setAdvanceImagedFromList} = headerSlice.actions

export default headerSlice.reducer