import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { useAuth } from "./app/auth";
import initializeApp from "./app/init";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { get, ref as rtdbRef } from "firebase/database";
import { H } from "highlight.run";
import { Buffer } from "buffer";
// import posthog from 'posthog-js'
import mixpanel from 'mixpanel-browser';


// import ReactPixel from 'react-facebook-pixel';


// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

// posthog.init('phc_hmlfqnxDPO7f58NJjY7tibWDh1QvLbv5bTsOhckfHdK', {
//   api_host: 'https://us.i.posthog.com',
// })
mixpanel.init('1aec2cf79499b33b34fe106249d83999', {track_pageview: "url-with-path", persistence: 'cookie', debug: true});
// let urlParams = new URLSearchParams(window.location.search);
// let distinctId = urlParams.get('distinctId');
// if(distinctId) {
//   console.log("distinctId", distinctId);
//   mixpanel.identify(distinctId);
// }


function App() {
  // Check for login and initialize axios
  const auth = useAuth();
  //eslint-disable-next-line
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth?.user?.["screenName"] || auth?.user?.["displayName"]) {
      listenToSubscriptionData();
    }

    // eslint-disable-next-line
  }, [auth?.user]);

  const listenToSubscriptionData = async () => {
    //listen to the subscription data from realtime user database
    if (auth && auth.user) {
      const subscriptionRef = rtdbRef(
        auth?.rtdb,
        "subscription/" + auth?.user.localId
      );
      get(subscriptionRef).then((snapshot) => {
      // onValue(subscriptionRef, (snapshot) => {
        const data = snapshot.val();
        printDataSize(data);
        setSubscriptionData(data);

        if (!data) {
          console.log("NO Subscription Data: Creating...");
          
          const getSubscriptionInfo = httpsCallable(
            auth?.functions,
            "getSubscriptionInfo"
          );
          getSubscriptionInfo();
        } else {
          const date = new Date(data["createdAt"]);

          //eslint-disable-next-line
          const createdAt =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
          H.identify(
            auth?.user?.["screenName"] || auth?.user?.["displayName"],
            {
              firebaseId: auth?.user?.["localId"],
              status: data["status"],
              createdAt: createdAt,
            }
          );

          if (data.status === "Lite Plan" && !data.renewDateAt) {
            //first time subcribing for lite plan
            //set the limit and set the renew

            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );

            const today = new Date();

            const renewalDate = new Date(today);
            renewalDate.setDate(today.getDate() + 30);
            //timestam
            console.log("Lite Plan with No Renwal Date: Updating...");
            updateSubscriptionInfo({
              renewDateAt: renewalDate.getTime(),
              credits: 150,
            });
          } else if (
            data.status === "Lite Plan" &&
            data.renewDateAt <= new Date().getTime()
          ) {
            const renewalDate = new Date(data.renewDateAt);
            renewalDate.setDate(renewalDate.getDate() + 30);
            //push renewDateAt 30 more dates
            //reset the limit
            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );

            console.log("Lite Plan Over the Renewal Date: Updating...");
            updateSubscriptionInfo({
              renewDateAt: renewalDate.getTime(),
              credits: 150,
            });
          } else if (data.status === "Hobbyist Plan" && !data.renewDateAt) {
            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );

            const today = new Date();

            const renewalDate = new Date(today);
            renewalDate.setDate(today.getDate() + 30);
            console.log("Hobbyist Plan with No Renwal Date: Updating...");
            updateSubscriptionInfo({
              renewDateAt: renewalDate.getTime(),
              credits: 10,
            });
          } else if (
            data.status === "Hobbyist Plan" &&
            data.renewDateAt <= new Date().getTime()
          ) {
            const renewalDate = new Date(data.renewDateAt);
            renewalDate.setDate(renewalDate.getDate() + 30);
            //push renewDateAt 30 more dates
            //reset the limit
            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );
            console.log("Hobbyist Plan Over the Renewal Date: Updating...");

            updateSubscriptionInfo({
              renewDateAt: renewalDate.getTime(),
              credits: 10,
            });
          } else if (data.status !== "trial" && !data.purchaseCompleteFunnelHit) {

            console.log("Purchase Complete");
            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );
            console.log("User Subscribed to Plan: Updating...");

            updateSubscriptionInfo({
              purchaseCompleteFunnelHit: true
            });
            try {
              window.fbq('track', 'Subscribe', {
                predicted_ltv: 100,
                currency: 'USD'
              });
              // posthog.capture('subscribed', {
              //   plan: data.status,
              //   currency: 'USD'
              // });
              mixpanel.track('subscribed', {
                plan: data.status,
                currency: 'USD'
              })
              console.log("META PIXEL: SUBSCRIBED");
            } catch (error) {
              console.log("META PIXEL DID NOT FIRE");
            }
            // ReactPixel.track('Subscribe', {
            //   predicted_ltv: 100,
            //   currency: 'USD'
            // });



          }
        }
      });
    }
  };

  const printDataSize = (data) => {
    let dataJSON = JSON.stringify(data);
    let dataJSONSize = Buffer.byteLength(dataJSON, "utf8"); //convert to kb
    dataJSONSize = dataJSONSize / 1024;
    console.log("<strong> Data Size App.js: " + dataJSONSize + " kb</strong>");
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          {/* <Route path="/auto-generate" element={} /> */}
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={
              <Navigate to={auth?.user ? "/app/welcome" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
