import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./app/store";
import { Provider } from "react-redux";
import SuspenseContent from "./containers/SuspenseContent";
import { AuthProvider } from "./app/auth";
import { H } from 'highlight.run';
import { ErrorBoundary } from '@highlight-run/react';


H.init('4d7zjmje', {
	serviceName: "frontend-app",
	tracingOrigins: true,
	networkRecording: {
		enabled: true,
		recordHeadersAndBody: true,
		urlBlocklist: [
			// insert full or partial urls that you don't want to record here
			// Out of the box, Highlight will not record these URLs (they can be safely removed):
      "https://localhost:3000",
      "http://localhost:3000",
			"https://www.googleapis.com/identitytoolkit",
			"https://securetoken.googleapis.com",
		],
	},
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <Suspense fallback={<SuspenseContent />}>
          <App />
        </Suspense>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
